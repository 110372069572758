var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('carouselBanner',{attrs:{"paddingTop":"24%","bannerType":1}}),_c('div',{staticClass:"content_area"},[_c('activity'),_c('div',{attrs:{"id":"point"}}),_c('advertising1'),_c('schoolCooperation',{attrs:{"advertising3":_vm.advertising3}}),(_vm.advertising2)?_c('div',{staticClass:"mobilizationBanner"},[_c('div',{staticClass:"mobilizationBannerImg"},[_c('div',{staticClass:"bannerInner",class:{
            pointer:
              _vm.advertising2.link_url &&
              _vm.advertising2.extend &&
              !_vm.advertising2.extend.is_show,
          },style:({ 'background-image': 'url(' + _vm.advertising2.img_url + ')' }),on:{"click":function($event){return _vm.handleDivLink(_vm.advertising2)}}},[(_vm.advertising2.extend && _vm.advertising2.extend.is_show)?_c('div',{staticClass:"bannerBtn",class:'btnStyle' + _vm.advertising2.extend.buttonStyle,style:({
              bottom: `${_vm.advertising2.extend.bottom}%`,
              right: `${_vm.advertising2.extend.right}%`,
              background: _vm.advertising2.extend.backgroundColor,
              borderColor: _vm.advertising2.extend.borderColor,
            }),on:{"click":function($event){return _vm.handleLink(_vm.advertising2.link_url)}}},[_c('el-button',{style:({
                fontSize: `0.${_vm.advertising2.extend.fontSize}rem`,
                color: _vm.advertising2.extend.color,
              }),attrs:{"type":"text"}},[_vm._v(_vm._s(_vm.advertising2.extend.content))])],1):_vm._e()])])]):_vm._e(),_c('education')],1),_c('div',{staticClass:"cooperation"},[_c('div',{staticClass:"content_area"},[_c('cooperation')],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }